/* START GENERAL CSS */
html,body {
	height: 100%;
	margin: 0;
}
body {
	font: @gfs/@glh @ff;
	margin: 0;
	color: @whate;
	min-width: 320px;
}
* {
	box-sizing: border-box;
}
.holder {
	margin: 0 auto;
	padding: 0 30px;
	max-width: 1240px;
}
#wrapper {
	position: relative;
	background: url("../images/bg-second.svg") no-repeat 50% 100%;
	background-size: cover;
}
#main {
	overflow: hidden;
	width: 100%;
	z-index: 1;

	h1 {
		font-size: 48px;
		line-height: 56px;
		margin: 0 0 25px;
		font-weight: 600;
	}

	h2 {
		font-size: 24px;
		line-height: 37px;
		margin: 0 0 50px;
		font-weight: 500;
	}

	.list-item {
		position: absolute;
		top: 14%;
		left: 60%;
		padding: 0 0 0 22px;
		margin: 0;
		list-style: none;
		width: 356px;
		font-size: 18px;
		line-height: 28px;
		font-weight: 500;
		z-index: 1;

		li {
			display: block;
			background: @whate;
			border-radius: @rad;
			color: @blueText;
			padding: 27px 48px;
			margin: 0 0 17px;
			position: relative;

			&:after {
			    content:'';
				display:block;
				clear:both;
				position: absolute;
				bottom: 12px;
				left: -22px;
				width: 50px;
				height: 50px;
				background: url("../images/pin.svg") no-repeat 0 100%;
				z-index: -1;
			}
		}
	}

	.maine-screen {
		display: flex;

		.box {
			width: 42%;

			&:first-child {
				width: 58%;
			}
		}

		.bg-box{

			.holder-img {
				position: relative;
				display: block;
				max-width: 463px;
				width: 100%;
				margin: 0 auto;
			}

			img {
				display: block;
				width: 100%;
				height: auto;
				z-index: 2;
				position: relative;
			}
		}

		.info-box {
			display: flex;
			flex-flow: row wrap;
			align-content: flex-end;
			padding: 0 0 6% 30px;
		}
	}

	.index-page {
		padding: 80px 0 0;
		position: relative;
		background: url("../images/bg-main.png") no-repeat 50% 100%;
		background-size: cover;

		&:after {
		    content:'';
			display:block;
			clear:both;
		    position: absolute;
		    top: 0;
		    left: 0;
			width: 100%;
			height: 100%;
			background: linear-gradient(97.82deg, rgba(2, 60, 98, 0.5) 6.66%, rgba(161, 41, 255, 0.8) 73.36%, #023C62 107.99%);
			transform: rotate(-180deg);
			z-index: -1;
		}
	}

	.holder-list {
		padding: 153px 0 60px;
		position: relative;
		width: 100%;

		&:after {
		    content:'';
			display:block;
			clear:both;
		    position: absolute;
		    top: 134px;
		    right: -7%;
			background: url("../images/second-ket.svg") no-repeat 50% 0;
			background-size: contain;
			height: 100%;
			max-width: 443px;
			width: 40%;
		}
	}

	.list-home {
		overflow: hidden;
		width: 100%;
		display: flex;
		padding: 0;
		margin: 0;
		list-style: none;
		font-size: 18px;
		line-height: 28px;
		position: relative;
		z-index: 2;

		a {
			display: inline-block;
			position: relative;
			color: @whate;
			text-decoration: none !important;

			&:after {
				content:'';
				position:absolute;
				bottom:0;
				left:0;
				width:100%;
				height: 1px;
				background: @whate;
				transform: scale(1);
				transition: transform .2s ease-in-out;
			}

			&:hover {
				&:after {
					transform: scale(0);
				}
			}
		}

		p {
			margin: 0 0 20px;
		}

		li {
			width: 35%;
			padding: 0 20px 0 69px;
			position: relative;
			margin: 0 0 63px;

			&:last-child {
				width: 30%;
				flex: 1 0;
			}
		}

		h3 {
			font-style: normal;
			font-weight: 600;
			font-size: 28px;
			line-height: 31px;
			margin: 0 0 15px;
		}

		.icon {
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			width: 50px;
			height: 50px;
			border-radius: 50px;
			background-color: @whate;
			background-repeat: no-repeat;
			background-position: 50% 50%;

			&.icon01 {
				background-image: url("../images/icon01.svg");
			}
			&.icon02 {
				background-image: url("../images/icon02.svg");
			}
			&.icon03 {
				background-image: url("../images/icon03.svg");
			}
			&.icon04 {
				background-image: url("../images/icon04.svg");
			}
			&.icon05 {
				background-image: url("../images/icon05.svg");
			}
			&.icon06 {
				background-image: url("../images/icon06.svg");
			}
			&.icon07 {
				background-image: url("../images/icon07.svg");
				background-size: 17px 25px;
			}
		}
	}

	.bg-list {

	}
}
@media (max-width: 1200px) {
	#main {

		.maine-screen {
			.info-box {
				padding: 0 0 3% 30px;
			}
		}

		h1 {
			font-size: 42px;
			line-height: 46px;
		}

		h2 {
			margin: 0;
		}

		.holder-list {
			&:after {

				width: 30%;
			}
		}
	}
}

@media (max-width: 1024px) {
	#main {

		h1 {
			font-size: 34px;
			line-height: 46px;
		}

		.holder-list {
			padding: 40px 0 ;
			&:after {
				top: 0%;
				width: 100%;
				max-width: 100%;
				opacity: 0.2;
				background-position: 50% 50%;
				z-index: -1;
			}
		}

		.list-home {
			flex-wrap: wrap;

			li {
				width: 50%;
				margin: 0 0 30px;
			}
		}
	}
}

@media (max-width: 768px) {
	body {
		font-size: 14px;
		line-height: 18px;
	}

	.holder {
		padding: 0 20px;
	}

	#main {

		h1 {
			font-size: 24px;
			line-height: 30px;
		}

		h2 {
			font-size: 18px;
			line-height: 24px;
		}

		.holder-list {
			padding: 40px 0 ;
			&:after {
				top: 0%;
				width: 100%;
				max-width: 100%;
				opacity: 0.2;
				background-position: 50% 50%;
			}
		}

		.list-home {
			flex-wrap: wrap;
			font-size: 14px;
			line-height: 18px;

			h3 {
				font-size: 18px;
				line-height: 24px;
			}

			li {
				width: 50%;
				margin: 0 ;
			}
		}

		.index-page {
			padding: 120px 0 0;
		}
	}
}

@media (max-width: 650px) {
	#main {

		.list-item {
			top:0;
			font-size: 14px;
			line-height: 18px;

			li {
				padding: 20px 20px;
			}
		}

		.maine-screen .box {
			width: 60%;

			&:first-child {
				width: 40%;
			}
		}
		.holder-list {
			padding: 40px 0 ;
		}

		.list-home {

			li {
				width: 100%;
			}
		}
	}
}
@media (max-width: 600px) {
	#main {

		.holder-list {
			padding: 16px 0 20px;
		}

		.list-home {
			font-size: 10px;
			line-height: 15px;

			h3 {
				margin: 0 0 7px;
			}

			li {
				padding: 0 0 0 54px;
			}

			.icon {
				transform: scale(0.8);
				top: -4px;
				left: -3px;
			}
		}

		.index-page {
			padding: 60px 0 0;
		}

		h1 {
			padding: 4px 0 0;
			font-size: 28px;
			line-height: 33px;
			margin: 0 0 12px;
		}

		h2 {
			font-size: 14px;
			line-height: 16px;
		}

		.list-item {
			top: auto;
			left: 40%;
			width: 176px;
			bottom: 62%;
			font-size: 10px;
			line-height: 15px;
			z-index: 1;

			li {
				padding: 18px 12px;

				&:after {
					bottom: 6px;
					left: -14px;
				}
			}
		}


		.maine-screen {
			align-items: stretch;
			padding: 10px 0 0;
			flex-wrap: wrap;

			.box {
				width: 100%;

				&:first-child {
					width: 100%;

					img {
						width: 236px;
						position: relative;
						z-index: 2;
					}
				}
			}

			.bg-box {

			}

			.info-box {
				padding: 22px 22px;
				width: calc(~'100% + 40px');
				margin: 0 -20px;
				background: #2f338c;
			}
		}
	}
}
