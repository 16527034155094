/* START HEADER */
#header {
	width: 100%;
	overflow: hidden;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
	height: 100px;
	padding: 25px 0 0;
	z-index: 5;

	.menu {
		display: flex;
		align-items: center;
	}

	.logo {
		float: left;
		overflow: hidden;
		text-indent: -9999px;
		margin:0;
		width: 96px;
		height: 50px;

		a,
		span {
			width:100%;
			height:100%;
			display:block;
			background: url(../images/logo.svg) no-repeat 0 0;
			background-size: 100% auto;
		}

		a {
			transition: opacity .2s ease-in-out;

			&:hover {
				opacity: 0.75;
			}
		}
	}

	.nav {
		z-index: 5;
		position: absolute;
		top: 0;
		right: 0;
		height: 100px;
		padding: 0;
		margin: 0;
		list-style: none;
		display: flex;
		align-items: center;
		flex:  1 0;
		justify-content: flex-end;

		li {
			margin: 0 0 0 46px;
		}

		a.link {
			color:@whate;
			text-decoration: none;
			display: inline-block;
			position: relative;
			padding: 0 0 2px;

			&:after {
				content:'';
				position:absolute;
				bottom:0;
				left:0;
				width:100%;
				height: 1px;
				background: @whate;
				transform: scale(0);
				transition: transform .2s ease-in-out;
			}

			&:hover {
				&:after {
					transform: scale(1);
				}
			}
		}

		.enter-link {
			display: block;
			height: 100px;
			padding: 20px 93px 20px 39px;
			font-size: 18px;
			line-height: 21px;
			color: @whate;
			background-color: @blue;
			text-decoration: none;
			text-transform: uppercase;
			position: relative;
			display: flex;
			align-items: center;

			&:after {
			    content:'';
				display:block;
				clear:both;
			    position: absolute;
			    top: 0;
			    right: 30px;
				background: url("../images/login-icon.svg") no-repeat 50% 50%;
				width: 40px;
				height: 100%;
				transition: right .2s ease-in-out;
			}

			&:hover {
				&:after {
					right: 20px;
				}
			}
		}
	}
}
@media (max-width: 1200px) {

}

@media (max-width: 1024px) {
	#header {
		height: 70px;
		padding: 10px 0 0;

		.nav {
			height: 70px;

			.enter-link {
				height: 70px;
			}
		}
	}
}

@media (max-width: 768px) {
	#header {
		height: 54px;
		padding: 7px 0 0;

		.logo {
			width: 77px;
			height: 40px;

			a,
			span {
				width: 77px;
				height: 40px;
			}
		}

		.nav {
			height: 54px;

			li {
				margin: 0 0 0 20px;
			}

			.enter-link {
				height: 54px;
				font-size: 14px;
				line-height: 18px;
				padding: 10px 60px 10px 20px;

				&:after {
					right: 20px;
					width: 24px;
					background-size: 100% auto;
				}

				&:hover:after {
					right: 10px;
				}
			}
		}
	}
}
@media (max-width: 500px) {
	#header {
		padding: 13px 0 0;

		.logo {
			width: 54px;
			height: 28px;

			a,
			span {
				width: 54px;
				height: 28px;
			}
		}

		.nav {
			font-size: 10px;
			line-height: 14px;

			.enter-link {
				font-size: 10px;
				line-height: 14px;
			}
		}
	}
}
/* END HEADER */
