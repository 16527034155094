/* common project variables */
@font-face {
  font-family: 'raleway';
  src: url('../fonts/raleway-bold-webfont.eot');
  src: url('../fonts/raleway-bold-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/raleway-bold-webfont.woff2') format('woff2'), url('../fonts/raleway-bold-webfont.woff') format('woff'), url('../fonts/raleway-bold-webfont.svg#ralewaybold') format('svg');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'raleway';
  src: url('../fonts/raleway-extrabold-webfont.eot');
  src: url('../fonts/raleway-extrabold-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/raleway-extrabold-webfont.woff2') format('woff2'), url('../fonts/raleway-extrabold-webfont.woff') format('woff'), url('../fonts/raleway-extrabold-webfont.svg#ralewayextrabold') format('svg');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'raleway';
  src: url('../fonts/raleway-regular-webfont.eot');
  src: url('../fonts/raleway-regular-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/raleway-regular-webfont.woff2') format('woff2'), url('../fonts/raleway-regular-webfont.woff') format('woff'), url('../fonts/raleway-regular-webfont.svg#ralewayregular') format('svg');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'raleway';
  src: url('../fonts/raleway-medium-webfont.eot');
  src: url('../fonts/raleway-medium-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/raleway-medium-webfont.woff2') format('woff2'), url('../fonts/raleway-medium-webfont.woff') format('woff'), url('../fonts/raleway-medium-webfont.svg#ralewaymedium') format('svg');
  font-weight: 500;
  font-style: normal;
}
/* font variables */
/* color variables */
/* border radius */
/* START GENERAL CSS */
html,
body {
  height: 100%;
  margin: 0;
}
body {
  font: 18px/24px 'raleway', sans-serif;
  margin: 0;
  color: #ffffff;
  min-width: 320px;
}
* {
  box-sizing: border-box;
}
.holder {
  margin: 0 auto;
  padding: 0 30px;
  max-width: 1240px;
}
#wrapper {
  position: relative;
  background: url("../images/bg-second.svg") no-repeat 50% 100%;
  background-size: cover;
}
#main {
  overflow: hidden;
  width: 100%;
  z-index: 1;
}
#main h1 {
  font-size: 48px;
  line-height: 56px;
  margin: 0 0 25px;
  font-weight: 600;
}
#main h2 {
  font-size: 24px;
  line-height: 37px;
  margin: 0 0 50px;
  font-weight: 500;
}
#main .list-item {
  position: absolute;
  top: 14%;
  left: 60%;
  padding: 0 0 0 22px;
  margin: 0;
  list-style: none;
  width: 356px;
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  z-index: 1;
}
#main .list-item li {
  display: block;
  background: #ffffff;
  border-radius: 20px;
  color: #3A36FE;
  padding: 27px 48px;
  margin: 0 0 17px;
  position: relative;
}
#main .list-item li:after {
  content: '';
  display: block;
  clear: both;
  position: absolute;
  bottom: 12px;
  left: -22px;
  width: 50px;
  height: 50px;
  background: url("../images/pin.svg") no-repeat 0 100%;
  z-index: -1;
}
#main .maine-screen {
  display: flex;
}
#main .maine-screen .box {
  width: 42%;
}
#main .maine-screen .box:first-child {
  width: 58%;
}
#main .maine-screen .bg-box .holder-img {
  position: relative;
  display: block;
  max-width: 463px;
  width: 100%;
  margin: 0 auto;
}
#main .maine-screen .bg-box img {
  display: block;
  width: 100%;
  height: auto;
  z-index: 2;
  position: relative;
}
#main .maine-screen .info-box {
  display: flex;
  flex-flow: row wrap;
  align-content: flex-end;
  padding: 0 0 6% 30px;
}
#main .index-page {
  padding: 80px 0 0;
  position: relative;
  background: url("../images/bg-main.png") no-repeat 50% 100%;
  background-size: cover;
}
#main .index-page:after {
  content: '';
  display: block;
  clear: both;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(97.82deg, rgba(2, 60, 98, 0.5) 6.66%, rgba(161, 41, 255, 0.8) 73.36%, #023C62 107.99%);
  transform: rotate(-180deg);
  z-index: -1;
}
#main .holder-list {
  padding: 153px 0 60px;
  position: relative;
  width: 100%;
}
#main .holder-list:after {
  content: '';
  display: block;
  clear: both;
  position: absolute;
  top: 134px;
  right: -7%;
  background: url("../images/second-ket.svg") no-repeat 50% 0;
  background-size: contain;
  height: 100%;
  max-width: 443px;
  width: 40%;
}
#main .list-home {
  overflow: hidden;
  width: 100%;
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: 18px;
  line-height: 28px;
  position: relative;
  z-index: 2;
}
#main .list-home a {
  display: inline-block;
  position: relative;
  color: #ffffff;
  text-decoration: none !important;
}
#main .list-home a:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: #ffffff;
  transform: scale(1);
  transition: transform 0.2s ease-in-out;
}
#main .list-home a:hover:after {
  transform: scale(0);
}
#main .list-home p {
  margin: 0 0 20px;
}
#main .list-home li {
  width: 35%;
  padding: 0 20px 0 69px;
  position: relative;
  margin: 0 0 63px;
}
#main .list-home li:last-child {
  width: 30%;
  flex: 1 0;
}
#main .list-home h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 31px;
  margin: 0 0 15px;
}
#main .list-home .icon {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
#main .list-home .icon.icon01 {
  background-image: url("../images/icon01.svg");
}
#main .list-home .icon.icon02 {
  background-image: url("../images/icon02.svg");
}
#main .list-home .icon.icon03 {
  background-image: url("../images/icon03.svg");
}
#main .list-home .icon.icon04 {
  background-image: url("../images/icon04.svg");
}
#main .list-home .icon.icon05 {
  background-image: url("../images/icon05.svg");
}
#main .list-home .icon.icon06 {
  background-image: url("../images/icon06.svg");
}
#main .list-home .icon.icon07 {
  background-image: url("../images/icon07.svg");
  background-size: 17px 25px;
}
@media (max-width: 1200px) {
  #main .maine-screen .info-box {
    padding: 0 0 3% 30px;
  }
  #main h1 {
    font-size: 42px;
    line-height: 46px;
  }
  #main h2 {
    margin: 0;
  }
  #main .holder-list:after {
    width: 30%;
  }
}
@media (max-width: 1024px) {
  #main h1 {
    font-size: 34px;
    line-height: 46px;
  }
  #main .holder-list {
    padding: 40px 0 ;
  }
  #main .holder-list:after {
    top: 0%;
    width: 100%;
    max-width: 100%;
    opacity: 0.2;
    background-position: 50% 50%;
    z-index: -1;
  }
  #main .list-home {
    flex-wrap: wrap;
  }
  #main .list-home li {
    width: 50%;
    margin: 0 0 30px;
  }
}
@media (max-width: 768px) {
  body {
    font-size: 14px;
    line-height: 18px;
  }
  .holder {
    padding: 0 20px;
  }
  #main h1 {
    font-size: 24px;
    line-height: 30px;
  }
  #main h2 {
    font-size: 18px;
    line-height: 24px;
  }
  #main .holder-list {
    padding: 40px 0 ;
  }
  #main .holder-list:after {
    top: 0%;
    width: 100%;
    max-width: 100%;
    opacity: 0.2;
    background-position: 50% 50%;
  }
  #main .list-home {
    flex-wrap: wrap;
    font-size: 14px;
    line-height: 18px;
  }
  #main .list-home h3 {
    font-size: 18px;
    line-height: 24px;
  }
  #main .list-home li {
    width: 50%;
    margin: 0 ;
  }
  #main .index-page {
    padding: 120px 0 0;
  }
}
@media (max-width: 650px) {
  #main .list-item {
    top: 0;
    font-size: 14px;
    line-height: 18px;
  }
  #main .list-item li {
    padding: 20px 20px;
  }
  #main .maine-screen .box {
    width: 60%;
  }
  #main .maine-screen .box:first-child {
    width: 40%;
  }
  #main .holder-list {
    padding: 40px 0 ;
  }
  #main .list-home li {
    width: 100%;
  }
}
@media (max-width: 600px) {
  #main .holder-list {
    padding: 16px 0 20px;
  }
  #main .list-home {
    font-size: 10px;
    line-height: 15px;
  }
  #main .list-home h3 {
    margin: 0 0 7px;
  }
  #main .list-home li {
    padding: 0 0 0 54px;
  }
  #main .list-home .icon {
    transform: scale(0.8);
    top: -4px;
    left: -3px;
  }
  #main .index-page {
    padding: 60px 0 0;
  }
  #main h1 {
    padding: 4px 0 0;
    font-size: 28px;
    line-height: 33px;
    margin: 0 0 12px;
  }
  #main h2 {
    font-size: 14px;
    line-height: 16px;
  }
  #main .list-item {
    top: auto;
    left: 40%;
    width: 176px;
    bottom: 62%;
    font-size: 10px;
    line-height: 15px;
    z-index: 1;
  }
  #main .list-item li {
    padding: 18px 12px;
  }
  #main .list-item li:after {
    bottom: 6px;
    left: -14px;
  }
  #main .maine-screen {
    align-items: stretch;
    padding: 10px 0 0;
    flex-wrap: wrap;
  }
  #main .maine-screen .box {
    width: 100%;
  }
  #main .maine-screen .box:first-child {
    width: 100%;
  }
  #main .maine-screen .box:first-child img {
    width: 236px;
    position: relative;
    z-index: 2;
  }
  #main .maine-screen .info-box {
    padding: 22px 22px;
    width: calc(100% + 40px);
    margin: 0 -20px;
    background: #2f338c;
  }
}
/* START HEADER */
#header {
  width: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100px;
  padding: 25px 0 0;
  z-index: 5;
}
#header .menu {
  display: flex;
  align-items: center;
}
#header .logo {
  float: left;
  overflow: hidden;
  text-indent: -9999px;
  margin: 0;
  width: 96px;
  height: 50px;
}
#header .logo a,
#header .logo span {
  width: 100%;
  height: 100%;
  display: block;
  background: url(../images/logo.svg) no-repeat 0 0;
  background-size: 100% auto;
}
#header .logo a {
  transition: opacity 0.2s ease-in-out;
}
#header .logo a:hover {
  opacity: 0.75;
}
#header .nav {
  z-index: 5;
  position: absolute;
  top: 0;
  right: 0;
  height: 100px;
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
  flex: 1 0;
  justify-content: flex-end;
}
#header .nav li {
  margin: 0 0 0 46px;
}
#header .nav a.link {
  color: #ffffff;
  text-decoration: none;
  display: inline-block;
  position: relative;
  padding: 0 0 2px;
}
#header .nav a.link:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: #ffffff;
  transform: scale(0);
  transition: transform 0.2s ease-in-out;
}
#header .nav a.link:hover:after {
  transform: scale(1);
}
#header .nav .enter-link {
  display: block;
  height: 100px;
  padding: 20px 93px 20px 39px;
  font-size: 18px;
  line-height: 21px;
  color: #ffffff;
  background-color: #3A36FE;
  text-decoration: none;
  text-transform: uppercase;
  position: relative;
  display: flex;
  align-items: center;
}
#header .nav .enter-link:after {
  content: '';
  display: block;
  clear: both;
  position: absolute;
  top: 0;
  right: 30px;
  background: url("../images/login-icon.svg") no-repeat 50% 50%;
  width: 40px;
  height: 100%;
  transition: right 0.2s ease-in-out;
}
#header .nav .enter-link:hover:after {
  right: 20px;
}
@media (max-width: 1024px) {
  #header {
    height: 70px;
    padding: 10px 0 0;
  }
  #header .nav {
    height: 70px;
  }
  #header .nav .enter-link {
    height: 70px;
  }
}
@media (max-width: 768px) {
  #header {
    height: 54px;
    padding: 7px 0 0;
  }
  #header .logo {
    width: 77px;
    height: 40px;
  }
  #header .logo a,
  #header .logo span {
    width: 77px;
    height: 40px;
  }
  #header .nav {
    height: 54px;
  }
  #header .nav li {
    margin: 0 0 0 20px;
  }
  #header .nav .enter-link {
    height: 54px;
    font-size: 14px;
    line-height: 18px;
    padding: 10px 60px 10px 20px;
  }
  #header .nav .enter-link:after {
    right: 20px;
    width: 24px;
    background-size: 100% auto;
  }
  #header .nav .enter-link:hover:after {
    right: 10px;
  }
}
@media (max-width: 500px) {
  #header {
    padding: 13px 0 0;
  }
  #header .logo {
    width: 54px;
    height: 28px;
  }
  #header .logo a,
  #header .logo span {
    width: 54px;
    height: 28px;
  }
  #header .nav {
    font-size: 10px;
    line-height: 14px;
  }
  #header .nav .enter-link {
    font-size: 10px;
    line-height: 14px;
  }
}
/* END HEADER */
/* START FOOTER */
#footer {
  overflow: hidden;
  width: 100%;
}
#footer address {
  font-style: normal;
}
#footer .holder-box {
  display: flex;
}
#footer .holder-box .box {
  width: 30%;
  text-align: right;
}
#footer .holder-box .box:first-child {
  width: 70%;
  flex: 1 0;
  text-align: left;
}
#footer .holder-block {
  display: flex;
}
#footer .holder-block .block {
  width: 212px;
}
#footer .holder-block .in {
  margin: 0 0 50px;
}
#footer .holder-block .in .title {
  display: block;
  margin: 0 0 12px;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
}
#footer .holder-block .in .text {
  display: block;
}
#footer .holder-block .in a {
  color: #ffffff;
  text-decoration: none !important;
  display: block;
  font-size: 18px;
  line-height: 21px;
}
#footer .holder-block .in a .title span {
  position: relative;
  display: inline-block;
}
#footer .holder-block .in a .title span:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: #ffffff;
  transform: scale(0);
  transition: transform 0.2s ease-in-out;
}
#footer .holder-block .in a:hover .title span:after {
  transform: scale(1);
}
#footer .list {
  padding: 0;
  margin: 0 0 20px;
  list-style: none;
}
#footer .list li {
  margin: 5px 0 0;
}
#footer .list li:first-child {
  margin: 0;
}
#footer .list .title {
  display: block;
  font-weight: 600;
}
#footer .list .text {
  font-size: 18px;
  line-height: 21px;
}
#footer .list .link-tel {
  font-size: 28px;
  line-height: 38px;
  text-decoration: none !important;
  cursor: default;
  color: #ffffff;
}
#footer .list .small-text {
  text-decoration: none !important;
  cursor: default;
  color: #ffffff;
}
#footer .list .link-male {
  display: inline-block;
  position: relative;
  font-size: 28px;
  line-height: 38px;
  color: #ffffff;
  text-decoration: none;
}
#footer .list .link-male:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: #ffffff;
  transform: scale(0);
  transition: transform 0.2s ease-in-out;
}
#footer .list .link-male:hover:after {
  transform: scale(1);
}
#footer .bottom-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 12px 0 0;
  font-size: 18px;
  line-height: 20px;
}
#footer .bottom-box .in-box {
  padding: 0 0 0 18px;
}
#footer .bottom-box p {
  margin: 0;
}
#footer .bottom-box .vk-link {
  display: inline-block;
  transition: opacity 0.2s ease-in-out;
}
#footer .bottom-box .vk-link:hover {
  opacity: 0.85;
}
@media (max-width: 1200px) {
  #footer .bottom-box {
    flex-wrap: wrap;
  }
  #footer .bottom-box .in-box {
    width: 100%;
    margin: 20px 0 0;
  }
  #footer .bottom-box .in-box:first-child {
    margin: 0;
  }
}
@media (max-width: 900px) {
  #footer .holder-block .in {
    margin: 0 0 20px;
  }
  #footer .holder-block .block {
    width: 33.3333%;
    padding: 0 20px 0 0;
  }
  #footer .holder-box {
    flex-wrap: wrap;
  }
  #footer .holder-box .box {
    width: 100%;
    text-align: center;
  }
  #footer .holder-box .box:first-child {
    width: 100%;
  }
}
@media (max-width: 768px) {
  #footer .bottom-box {
    font-size: 14px;
    line-height: 18px;
    padding: 0;
  }
  #footer .list .text {
    font-size: 14px;
    line-height: 18px;
  }
  #footer .list .link-tel,
  #footer .list .link-male {
    font-size: 18px;
    line-height: 24px;
  }
  #footer .holder-block {
    flex-wrap: wrap;
  }
  #footer .holder-block .block {
    width: 50%;
  }
  #footer .holder-block .in a {
    font-size: 14px;
    line-height: 18px;
  }
}
@media (max-width: 650px) {
  #footer .holder-box .box:first-child {
    padding: 20px 0 0;
    margin: 0 0 20px;
  }
  #footer .holder-block .block {
    width: 100%;
  }
  #footer .holder-block .block br {
    display: none;
  }
}
/* END FOOTER */
/* General reset form */
form,
fieldset {
  margin: 0;
  padding: 0;
  border-style: none;
}
input,
select,
textarea,
select,
button {
  vertical-align: middle;
}
button,
input {
  *overflow: visible;
  line-height: normal;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button;
}
label,
select,
button,
input[type="button"],
input[type="reset"],
input[type="submit"],
input[type="radio"],
input[type="checkbox"] {
  cursor: pointer;
}
textarea {
  overflow: auto;
  vertical-align: top;
}
.btn {
  display: block;
  text-decoration: none;
  color: #414141;
  padding: 13px 55px 13px 20px;
  text-transform: uppercase;
  position: relative;
  background: #fff;
  width: 100%;
  border: none;
  cursor: pointer;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  text-align: left;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
}
.mosaic-box .item .btn {
  margin: 25px 0 0;
}
.btn:before {
  content: '';
  position: absolute;
  top: 50%;
  right: 19px;
  transform: translateY(-50%);
  height: 19px;
  width: 32px;
  background-image: url(../images/arrow.svg);
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  transition: right 0.2s ease;
}
.btn:hover:before {
  right: 9px;
}
.modal .hold-form {
  position: relative;
  width: 100%;
}
.modal input.fake-link {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}
.modal input.fake-link:hover + .btn:before {
  right: 9px;
}
.modal .btn {
  z-index: 1;
}
.modal {
  width: 610px;
  padding: 75px 70px 70px;
  position: absolute;
  left: -9999px;
  top: -9999px;
  background: #000;
  z-index: 1000;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.modal .in {
  padding: 0 72px;
  font-size: 18px;
  line-height: 21px;
}
.modal p {
  text-align: center;
  margin: 0 0 32px;
}
.modal h3 {
  font-size: 28px;
  line-height: 33px;
  margin: 0 0 46px;
  text-align: center;
}
.modal input[type="text"],
.modal input[type="email"],
.modal input[type="password"],
textarea {
  width: 100%;
  height: 66px;
  font-size: 18px;
  line-height: 20px;
  padding: 23px 20px;
  border: 2px solid #ffffff;
  margin: 0 0 18px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: #000;
  color: #ffffff;
  outline: 0 none;
}
.modal input[type="text"]::placeholder,
.modal input[type="email"]::placeholder,
.modal input[type="password"]::placeholder,
textarea::placeholder {
  color: #ffffff;
}
.modal input[type="text"].error,
.modal input[type="email"].error,
.modal input[type="password"].error,
textarea.error {
  border-color: #ff0006;
  color: #ff0006;
}
textarea {
  height: 110px;
  max-width: 100%;
  min-width: 100%;
}
.modal .btn {
  padding-top: 25px;
  padding-bottom: 25px;
}
.modal .close {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 28px;
  height: 28px;
  background: url(../images/bg-close.svg) 0 0 no-repeat;
  background-size: cover;
  cursor: pointer;
}
.modal .hold-link {
  text-align: center;
  padding: 20px 0 0;
}
.modal .hold-link a {
  color: #fff;
  position: relative;
  display: inline-block;
  text-decoration: none;
  padding: 0 0 1px;
}
.modal .hold-link a:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: #ffffff;
  transform: scale(1);
  transition: transform 0.2s ease-in-out;
}
.modal .hold-link a:hover:after {
  transform: scale(0);
}
@media (max-width: 1200px) {
  #footer .bottom-box {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  #footer .bottom-box .in-box {
    width: 100%;
    margin: 20px 0 0;
  }
  #footer .bottom-box .in-box:first-child {
    margin: 0;
  }
}
@media (max-width: 900px) {
  #footer .holder-block .in {
    margin: 0 0 20px;
  }
  #footer .holder-block .block {
    width: 33.3333%;
    padding: 0 20px 0 0;
  }
  #footer .holder-box {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  #footer .holder-box .box {
    width: 100%;
    text-align: center;
  }
  #footer .holder-box .box:first-child {
    width: 100%;
  }
}
@media (max-width: 768px) {
  #footer .bottom-box {
    font-size: 14px;
    line-height: 18px;
    padding: 0;
  }
  #footer .list .text {
    font-size: 14px;
    line-height: 18px;
  }
  #footer .list .link-male,
  #footer .list .link-tel {
    font-size: 18px;
    line-height: 24px;
  }
  #footer .holder-block {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  #footer .holder-block .block {
    width: 50%;
  }
  #footer .holder-block .in a {
    font-size: 14px;
    line-height: 18px;
  }
}
@media (max-width: 650px) {
  #footer .holder-box .box:first-child {
    padding: 20px 0 0;
    margin: 0 0 20px;
  }
  #footer .holder-block .block br {
    display: none;
  }
}
.modal-purple {
  background: #4833a8;
  background: -moz-linear-gradient(top, #4833a8 1%, #153875 100%);
  background: -webkit-linear-gradient(top, #4833a8 1%, #153875 100%);
  background: linear-gradient(to bottom, #4833a8 1%, #153875 100%);
}
.modal-purple input[type="text"],
.modal-purple input[type="email"],
.modal input[type="password"],
.modal-purple textarea {
  background: none;
}
.btn-purple {
  background: #3a36fe;
  color: #fff;
}
.btn-purple:before {
  background-image: url(../images/arrow-white.svg);
}
@media (max-width: 1200px) {
  .btn {
    font-size: 14px;
    padding: 10px 45px 10px 15px;
  }
  .btn:before {
    right: 15px;
    height: 16px;
    width: 25px;
  }
  .modal {
    padding: 50px;
    min-height: 620px;
  }
  .modal input[type="text"],
  .modal input[type="email"],
  .modal input[type="password"] {
    height: 50px;
    padding: 15px;
  }
  textarea {
    padding: 15px;
  }
  .modal .btn {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
@media (max-width: 768px) {
  .modal {
    width: 90%;
  }
  .modal h3 {
    font-size: 20px;
    line-height: 26px;
    margin: 0 0 20px;
  }
  .modal .close {
    width: 20px;
    height: 20px;
  }
}
@media (max-width: 600px) {
  #footer .list {
    margin: 0 0 16px;
  }
  #footer .bottom-box {
    overflow: hidden;
    justify-content: flex-start;
  }
  #footer .bottom-box .in-box {
    width: auto;
    padding: 0;
    margin: 0;
  }
  #footer .bottom-box .in-box .vk-link {
    width: 88px;
    margin: 0 20px 0 0;
  }
  #footer .bottom-box .in-box .vk-link img {
    display: block;
    width: 100%;
    height: auto;
  }
  #footer .bottom-box .in-box:first-child {
    order: 2;
  }
  #footer .bottom-box .in-box:last-child {
    order: 1;
  }
  #footer .holder-box .box {
    text-align: left;
  }
}
@media (max-width: 500px) {
  .modal {
    padding: 45px 20px;
    min-height: 500px;
  }
  .modal h3 {
    font-size: 16px;
    line-height: 21px;
  }
  .modal input[type="text"],
  .modal input[type="email"],
  .modal input[type="password"],
  textarea {
    font-size: 14px;
  }
}
