/* START FOOTER */
#footer {
  overflow: hidden;
  width: 100%;

  address {
    font-style: normal;
  }

  .holder-box {
    display: flex;

    .box {
      width: 30%;
      text-align: right;

      &:first-child {
        width: 70%;
        flex: 1 0;
        text-align: left;
      }
    }
  }

  .holder-block {
    display: flex;

    .block {
      width: 212px;
    }

    .in {
      margin: 0 0 50px;

      .title {
        display: block;
        margin: 0 0 12px;
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
        text-transform: uppercase;
      }

      .text {
        display: block;

      }

      a {
        color: @whate;
        text-decoration: none !important;
        display: block;
        font-size: 18px;
        line-height: 21px;

        .title {

          span {
            position: relative;
            display: inline-block;

            &:after {
              content:'';
              position:absolute;
              bottom:0;
              left:0;
              width:100%;
              height: 1px;
              background: @whate;
              transform: scale(0);
              transition: transform .2s ease-in-out;
            }

          }
        }

        &:hover {
          .title {
            span {
              &:after {
                transform: scale(1);
              }
            }
          }
        }
      }
    }
  }

  .list {
    padding: 0;
    margin: 0 0 20px;
    list-style: none;

    li {
      margin: 5px 0 0;

      &:first-child {
        margin: 0;
      }
    }

    .title {
      display: block;
      font-weight: 600;
    }

    .text {
      font-size: 18px;
      line-height: 21px;
    }

    .link-tel {
      font-size: 28px;
      line-height: 38px;
      text-decoration: none !important;
      cursor: default;
      color: @whate;
    }

    .small-text {
      text-decoration: none !important;
      cursor: default;
      color: @whate;
    }

    .link-male {
      display: inline-block;
      position: relative;
      font-size: 28px;
      line-height: 38px;
      color: @whate;
      text-decoration: none;

      &:after {
        content:'';
        position:absolute;
        bottom:0;
        left:0;
        width:100%;
        height: 1px;
        background: @whate;
        transform: scale(0);
        transition: transform .2s ease-in-out;
      }

      &:hover {
        &:after {
          transform: scale(1);
        }
      }
    }
  }

  .bottom-box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 12px 0 0;
    font-size: 18px;
    line-height: 20px;

    .in-box {
      padding:0 0 0 18px;
    }

    p {
      margin: 0;
    }

    .vk-link {
      display: inline-block;
      transition: opacity .2s ease-in-out;

      &:hover {
        opacity: 0.85;
      }
    }
  }
}

@media (max-width: 1200px) {
  #footer {
    .bottom-box {
      flex-wrap: wrap;

      .in-box {
        width: 100%;
        margin: 20px 0 0;

        &:first-child {
          margin: 0;
        }
      }
    }
  }
}
@media (max-width: 900px) {
  #footer {

    .holder-block {

      .in {
        margin: 0 0 20px;
      }

      .block {
        width: 33.3333%;
        padding: 0 20px 0 0;
      }
    }

    .holder-box {
      flex-wrap: wrap;

      .box {
        width: 100%;
        text-align: center;

        &:first-child {
          width: 100%;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  #footer {

    .bottom-box {
      font-size: 14px;
      line-height: 18px;
      padding: 0;
    }

    .list {

      .text {
        font-size: 14px;
        line-height: 18px;
      }

      .link-tel,
      .link-male {
        font-size: 18px;
        line-height: 24px;
      }
    }

    .holder-block {
      flex-wrap: wrap;

      .block {
        width: 50%;
      }

      .in {
        a {
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
  }
}
@media (max-width: 650px) {
  #footer {
    .holder-box {
      .box{

        &:first-child {
          padding: 20px 0 0;
          margin: 0 0 20px;
        }
      }
    }
    .holder-block {
      .block {
        width: 100%;

        br {
          display: none;
        }
      }
    }
  }
}
/* END FOOTER */

/* General reset form */
form, fieldset {
  margin: 0;
  padding: 0;
  border-style: none;
}
input,
select,
textarea,
select,
button {
  vertical-align: middle;
}
button,
input {
  *overflow: visible;
  line-height: normal;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button;
}
label,
select,
button,
input[type="button"],
input[type="reset"],
input[type="submit"],
input[type="radio"],
input[type="checkbox"] {
  cursor: pointer;
}
textarea {
  overflow: auto;
  vertical-align: top;
}

.btn {
  display: block;
  text-decoration: none;
  color: #414141;
  padding:13px 55px 13px 20px;
  text-transform: uppercase;
  position: relative;
  background: #fff;
  width: 100%;
  border: none;
  cursor: pointer;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  text-align: left;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
}
.mosaic-box .item .btn {
  margin: 25px 0 0;
}
.btn:before {
  content: '';
  position: absolute;
  top: 50%;
  right: 19px;
  transform: translateY(-50%);
  height: 19px;
  width: 32px;
  background-image: url(../images/arrow.svg);
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  transition: right 0.2s ease;
}
.btn:hover:before {
  right: 9px;
}
.modal {
  .hold-form {
    position: relative;
    width: 100%;
  }
  input.fake-link {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;

    &:hover + .btn {
      &:before {
        right: 9px;
      }
    }
  }

  .btn {
    z-index: 1;
  }
}
.modal {
  width: 610px;
  padding: 75px 70px 70px;
  position: absolute;
  left: -9999px;
  top: -9999px;
  background: #000;
  z-index: 1000;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  min-height: 600px;
  display: flex;
  flex-direction:column;
  justify-content: center;

  .in {
    padding: 0 72px;
    font-size: 18px;
    line-height: 21px;
  }
}
.modal p {
  text-align: center;
  margin:0 0 32px;
}
.modal h3 {
  font-size: 28px;
  line-height: 33px;
  margin:0 0 46px;
  text-align: center;
}
.modal input[type="text"],
.modal input[type="email"],
.modal input[type="password"],
textarea {
  width: 100%;
  height: 66px;
  font-size:18px;
  line-height:20px;
  padding: 23px 20px;
  border: 2px solid @whate;
  margin:0 0 18px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: #000;
  color: @whate;
  outline:0 none;

  &::placeholder {
    color: @whate;
  }

  &.error {
    border-color: @red;
    color: @red;
  }
}
textarea {
  height: 110px;
  max-width: 100%;
  min-width: 100%;
}
.modal .btn {
  padding-top: 25px;
  padding-bottom: 25px;
}
.modal .close {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 28px;
  height: 28px;
  background: url(../images/bg-close.svg) 0 0 no-repeat;
  background-size: cover;
  cursor: pointer;
}
.modal .hold-link {
  text-align: center;
  padding: 20px 0 0;
}
.modal .hold-link a {
  color: #fff;
  position: relative;
  display: inline-block;
  text-decoration: none;
  padding: 0 0 1px;

  &:after {
    content:'';
    position:absolute;
    bottom:0;
    left:0;
    width:100%;
    height: 1px;
    background: @whate;
    transform: scale(1);
    transition: transform .2s ease-in-out;
  }

  &:hover {
    &:after {
      transform: scale(0);
    }
  }
}
@media (max-width: 1200px) {
  #footer .bottom-box {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
  }
  #footer .bottom-box .in-box {
    width: 100%;
    margin: 20px 0 0
  }
  #footer .bottom-box .in-box:first-child {
    margin: 0
  }
}
@media (max-width: 900px) {
  #footer .holder-block .in {
    margin: 0 0 20px
  }
  #footer .holder-block .block {
    width: 33.3333%;
    padding: 0 20px 0 0
  }
  #footer .holder-box {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
  }
  #footer .holder-box .box {
    width: 100%;
    text-align: center
  }
  #footer .holder-box .box:first-child {
    width: 100%
  }
}
@media (max-width: 768px) {
  #footer .bottom-box {
    font-size: 14px;
    line-height: 18px;
    padding: 0
  }
  #footer .list .text {
    font-size: 14px;
    line-height: 18px
  }
  #footer .list .link-male, #footer .list .link-tel {
    font-size: 18px;
    line-height: 24px
  }
  #footer .holder-block {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
  }
  #footer .holder-block .block {
    width: 50%
  }
  #footer .holder-block .in a {
    font-size: 14px;
    line-height: 18px
  }
}
@media (max-width: 650px) {
  #footer .holder-box .box:first-child {
    padding: 20px 0 0;
    margin: 0 0 20px;
  }

  #footer .holder-block .block br {
    display: none
  }
}
.modal-purple {
  background: #4833a8;
  background: -moz-linear-gradient(top, #4833a8 1%, #153875 100%);
  background: -webkit-linear-gradient(top, #4833a8 1%,#153875 100%);
  background: linear-gradient(to bottom, #4833a8 1%,#153875 100%);
}
.modal-purple input[type="text"],
.modal-purple input[type="email"],
.modal input[type="password"],
.modal-purple textarea {
  background: none;
}
.btn-purple {
  background: #3a36fe;
  color: #fff;
}
.btn-purple:before {
  background-image: url(../images/arrow-white.svg);
}

@media (max-width:  1200px) {

  .btn {
    font-size: 14px;
    padding: 10px 45px 10px 15px;
  }
  .btn:before {
    right: 15px;
    height: 16px;
    width: 25px;
  }
  .modal {
    padding: 50px;
    min-height: 620px;
  }
  .modal input[type="text"],
  .modal input[type="email"],
  .modal input[type="password"]{
    height: 50px;
    padding: 15px;
  }
  textarea {
    padding: 15px;
  }
  .modal .btn {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

@media (max-width:  768px) {
  .modal {
    width: 90%;
  }
  .modal h3 {
    font-size: 20px;
    line-height: 26px;
    margin: 0 0 20px;
  }
  .modal .close {
    width: 20px;
    height: 20px;
  }
}
@media (max-width:  600px) {
  #footer {

    .list {
      margin: 0 0 16px;
    }

    .bottom-box {
      overflow: hidden;
      justify-content: flex-start;

      .in-box {
        width: auto;
        padding: 0;
        margin: 0;

        .vk-link {
          width: 88px;
          margin: 0 20px 0 0;

          img {
            display: block;
            width: 100%;
            height: auto;
          }
        }

        &:first-child {
          order: 2;
        }

        &:last-child {
          order: 1;
        }
      }
    }

    .holder-box {

      .box {
        text-align: left;
      }
    }
  }
}
@media (max-width:  500px) {
  .modal {
    padding: 45px 20px;
    min-height: 500px;
  }
  .modal h3 {
    font-size: 16px;
    line-height: 21px;
  }
  .modal input[type="text"],
  .modal input[type="email"],
  .modal input[type="password"],
  textarea {
    font-size: 14px;
  }
}
