/* common project variables */
@font-face {
  font-family: 'raleway';
  src: url('../fonts/raleway-bold-webfont.eot');
  src: url('../fonts/raleway-bold-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/raleway-bold-webfont.woff2') format('woff2'),
  url('../fonts/raleway-bold-webfont.woff') format('woff'),
  url('../fonts/raleway-bold-webfont.svg#ralewaybold') format('svg');
  font-weight: 700;
  font-style: normal;

}
@font-face {
  font-family: 'raleway';
  src: url('../fonts/raleway-extrabold-webfont.eot');
  src: url('../fonts/raleway-extrabold-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/raleway-extrabold-webfont.woff2') format('woff2'),
  url('../fonts/raleway-extrabold-webfont.woff') format('woff'),
  url('../fonts/raleway-extrabold-webfont.svg#ralewayextrabold') format('svg');
  font-weight: 800;
  font-style: normal;

}
@font-face {
  font-family: 'raleway';
  src: url('../fonts/raleway-regular-webfont.eot');
  src: url('../fonts/raleway-regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/raleway-regular-webfont.woff2') format('woff2'),
  url('../fonts/raleway-regular-webfont.woff') format('woff'),
  url('../fonts/raleway-regular-webfont.svg#ralewayregular') format('svg');
  font-weight: 400;
  font-style: normal;

}
@font-face {
  font-family: 'raleway';
  src: url('../fonts/raleway-medium-webfont.eot');
  src: url('../fonts/raleway-medium-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/raleway-medium-webfont.woff2') format('woff2'),
  url('../fonts/raleway-medium-webfont.woff') format('woff'),
  url('../fonts/raleway-medium-webfont.svg#ralewaymedium') format('svg');
  font-weight: 500;
  font-style: normal;

}
/* font variables */
@ff: 'raleway', sans-serif;
@gfs: 18px;
@glh: 24px;

/* color variables */
@whate: #ffffff;
@blue: #3A36FE;
@blueDarck: #003E63;
@blueText: #3A36FE;
@bgFooter: #1e1c5b;
@red: #ff0006;

/* border radius */
@rad: 20px;
